Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("copy-board-wrapper")) {
        Global.components.push(
            new Vue({
                el: '#copy-board-wrapper',
                store,
                data: function () {
                    return {
                        loading: false,
                        complete: false,
                        copied: false,
                        customerCopied: false,
                        copyUrl: '#',
                        showCopyModal: false,
                        modalSmall: false,
                        copyKey: '',
                        customer: {
                            name: '',
                            id: '',
                            redirect: '',
                        },
                        copy: {
                            url: null,
                            id: null,
                            customer: null,
                            allowed: false
                        }
                    }
                },
                mounted() {
                    this.copy.customer = parseInt(this.$el.getAttribute('data-customer-id'), 10);
                },
                computed: {
                    errorObject: {
                        get: function () {
                            return this.$store.getters.errorObject
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setErrorObject', newValue)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },

                },
                methods: {
                    selectCustomer: function ($event) {
                        this.copy.customer = $event;
                    },
                    copyObject: function (url, objectID, isAccountManager) {
                        if(!this.copied && !this.loading) {
                            this.showCopyModal = true;
                            this.copy.url = url;
                            this.copy.id = objectID;
                            if (isAccountManager) {
                                this.copy.allowed = true;
                            }
                        }
                    },
                    executeCopyObject: function(type, url, objectID)
                    {
                        if(!this.copied && !this.loading){
                            this.loading = true;
                            const that = this;
                            let formData = new FormData();
                            formData.append(type, objectID);

                            if (this.copy.customer && this.copy.allowed) {
                                formData.append('customer', this.copy.customer);
                                this.modalSmall = true;
                            }

                            if(type === 'project' && document.getElementById("copy_contract_variables").checked) {
                                formData.append('copy_contract_variables', '1');
                            }

                            API.POST(url, formData).then(response => {
                                if(response.status === 'success'){
                                    that.loading = false;
                                    if (response.customer) {
                                        that.copied = false;
                                        this.customerCopied = true;
                                        this.customer.name = response.customer.name;
                                        this.customer.id = response.customer.id;
                                        this.customer.redirect = response.customer.redirect;
                                    } else {
                                        this.customerCopied = false;
                                        that.copied = true;
                                        this.showCopyModal = false;
                                        that.copyUrl = response.url
                                        that.messageText = response.message;
                                        window.open(response.url, '_blank').focus();
                                        this.modalSmall = false;
                                    }
                                }else if(response.status === 'error'){
                                    that.errorObject = response.data;
                                    this.loading = false;
                                    this.copied = false;
                                }
                            });
                        }
                    },
                    submitLoginForm() {
                        this.$refs.loginForm.submit();
                    }
                },
            })
        )
    }
});
