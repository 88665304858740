Vue.component('customer-select', {
    props: ['value', 'default', 'customers-url', 'icon'],
    data: function () {
        return {
            inputValue: null,
            search: '',
            customersLoading: false,
            customersData: {},
            selectedCustomer: null
        }
    },
    mounted() {
        this.getCustomers(1);
    },
    destroyed() {
    },
    computed: {
        customers() {
            if(this.customersData !== null && this.customersData.hasOwnProperty('data')) {
                return this.customersData.data;
            }

            return [];
        }
    },
    methods: {
        getCustomers(page) {
            this.selectedCustomer = this.default;
            if (typeof (page) == 'undefined') {
                page = 1;
            }

            this.customersLoading = true;
            let url = this.customersUrl;

            // Page number
            url = Global.updateQueryStringParameter(url, 'page', page);

            // Accountmanager ID
            // let accountmanager = parseInt(this.accountmanager);
            // if(!isNaN(accountmanager) && accountmanager > 0) {
            //     url = Global.updateQueryStringParameter(url, 'accountmanager', accountmanager);
            // }

            // Search query
            let query = (this.search || '').trim();
            if (query != '') {
                url = Global.updateQueryStringParameter(url, 'query', query);
            }

            url = Global.updateQueryStringParameter(url, 'customer', this.default)

            // Execute XHR request
            API.GET(url).then(response => {
                this.customersData = response;

                this.customersLoading = false;
            });
        },
        gotoCustomerPage(page) {
            this.getCustomers(page);
        },
        onQueryKeyup() {
            this.customersLoading = true;

            clearTimeout(this.queryKeyUpTimer);
            this.queryKeyUpTimer = setTimeout(function () {
                this.getCustomers();
                this.queryKeyUpTimer = null;
            }.bind(this), 500);
        },
        onItemClick(item) {
            this.selectedCustomer = item.id;
            this.$emit('customer', item.id);
        },

    },
    template:
        '<div class="customer-select">\n' +
        '    <label for="search_customer">Zoek klant</label>\n' +
        '    <input type="text" id="search_customer" v-model="search" @keyup="onQueryKeyup" />\n' +
        '\n' +
        '    <div class="accountmanager-overview__results modal__results">\n' +
        '        <div v-if="customersLoading" class="accountmanager-overview__results-loading">\n' +
        '            <div class="loading-blink">Gegevens worden opgehaald<span class="loading-blink__dot">.</span><span class="loading-blink__dot">.</span><span class="loading-blink__dot">.</span></div>\n' +
        '        </div>\n' +
        '\n' +
        '        <div v-if="customers.length == 0" class="accountmanager-overview__results-empty">Geen resultaten</div>\n' +
        '        <div v-else class="accountmanager-overview__results-container">\n' +
        '            <div class="grid-x">\n' +
        '\n' +
        '                <div class="cell small-24" v-if="customersData && customersData.meta">\n' +
        '                    <div class="accountmanager-overview__results-count">{{ customersData.meta.from }} - {{ customersData.meta.to }} van {{ customersData.meta.total }}</div>\n' +
        '                </div>\n' +
        '\n' +
        '                <div class="cell small-24">\n' +
        '                    <div class="accountmanager-overview__results-header accountmanager-overview__results-header--flex">\n' +
        '                        <div class="grid-x align-middle">\n' +
        '                            <div class="cell small-24 medium-24 large-18">\n' +
        '                                Naam\n' +
        '                            </div>\n' +
        '                            <div class="cell show-for-large large-6">\n' +
        '                                Accountmanager\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '\n' +
        '                <div class="cell small-24">\n' +
        '\n' +
        '                    <div class="accountmanager-overview__results-list">\n' +
        '\n' +
        '                        <div class="grid-x">\n' +
        '\n' +
        '                            <div v-for="item in customers" :key="item.name" class="cell small-24">\n' +
        '\n' +
        '                                <div class="accountmanager-overview__results-item" :class="{ \'accountmanager-overview__results-item--active\': item.id === selectedCustomer }" @click.prevent="onItemClick(item)">\n' +
        '                                    <div class="grid-x align-middle">\n' +
        '                                        <div class="cell small-24 medium-24 large-18">\n' +
        '                                            {{ item.name }}\n' +
        '                                        </div>\n' +
        '                                        <div class="cell show-for-large large-6">\n' +
        '                                            {{ item.accountmanager }}\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <span class="accountmanager-overview__results-icon" v-html="icon"></span>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="cell small-24" v-if="customersData && customersData.meta">\n' +
        '                    <laravel-vue-pagination :data="customersData" @pagination-change-page="gotoCustomerPage"></laravel-vue-pagination>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>'
})
